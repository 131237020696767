<template>
  <div class="dealer-company-employee">
    <div class="dealer-company-employee-list">
      <el-table :data="employees">
        <el-table-column label="员工名称" align="center" prop="name"></el-table-column>
        <el-table-column label="手机号" align="center" prop="phone"></el-table-column>
        <el-table-column label="剩余免费杯量" align="center" prop="canUseCups"></el-table-column>
        <el-table-column label="本月制作杯量" align="center" prop="makeCups"></el-table-column>
        <el-table-column label="每月定额杯量" align="center" prop="freeCups"></el-table-column>

        <el-table-column label="操作" align="center">
          <template slot="header" slot-scope="scope">
            <el-input
              @change="handleSearchEmployee"
              v-model="pagination.name"
              size="mini"
              placeholder="搜索员工"/>
          </template>
          <template slot-scope="scope">
            <el-button type="text" class="warning" @click="showResetEmployeePassword(scope.row, scope.$index)">重置密码
            </el-button>
            <el-button type="text" class="warning" @click="showResetEmployeeCups(scope.row,  scope.$index)"> 杯量设定
            </el-button>
            <el-button type="text" class="danger" @click="handleDeleteEmployee(scope.row,  scope.$index)"> 删除员工
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>

    <c-dialog title="重置密码" :visible.sync="dialogFormVisible" width="32rem" height="18rem">
      <el-form size="mini">
        <el-form-item label="新的密码">
          <el-input v-model="reset.password" type="password" autocomplete="off" placeholder="请输入新的密码"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码">
          <el-input v-model="reset.repeatedPassword" type="password" autocomplete="off"
                    placeholder="请再次输入新的密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleResetEmployeePassword">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </c-dialog>

    <c-dialog title="重置杯量" :visible.sync="dialogResetCupVisible" width="32rem" height="18rem">
      <el-form size="mini">
        <el-form-item label="每月限额">
          <el-input v-model="cup.freeCups" type="number" autocomplete="off" placeholder="每月额度"></el-input>
        </el-form-item>
        <el-form-item label="本月额度">
          <el-input v-model="cup.canUseCups" type="number" autocomplete="off"
                    placeholder="本月额度"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleResetEmployeeCups">确 定</el-button>
          <el-button @click="dialogResetCupVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </c-dialog>
  </div>

</template>

<script>
  import {
    companyEmployeeUnion,
    companyEmployeeList,
    companyEmployeeDelete,
    companyEmployeeResetPassword,
    companyEmployeeResetCups,
  } from '@/api/marketing/combination/company'
  import {checkObj} from '@/util/checkLogin'
  import CDialog from '@/components/Dialog'

  export default {
    name: 'dealer_company_detail_employee',
    components: {
      CDialog
    },
    mounted: function () {
    },
    data: function () {
      return {
        pagination: {
          companyUuid: '',
          name: '',
          current: 0,
          start: 0,
          limit: 10,
        },
        reset: {
          password: '',
          repeatedPassword: '',
          employeeUuid: '',
          companyUuid: '',
        },
        cup: {
          freeCups: 0,
          canUseCups: 0,
          employeeUuid: '',
          companyUuid: '',
        },
        dialogFormVisible: false,
        dialogResetCupVisible: false,
        employeeIndex: 0,
        employees: []
      }
    },
    methods: {
      showResetEmployeePassword(employee, index) {
        this.reset = {
          password: '',
          repeatedPassword: '',
          employeeUuid: employee.uuid,
          companyUuid: this.pagination.companyUuid,
        }
        this.dialogFormVisible = true
      },
      showResetEmployeeCups(employee, index) {
        this.employeeIndex = index
        this.cup = {
          freeCups: employee.freeCups,
          canUseCups: employee.canUseCups,
          employeeUuid: employee.uuid,
          companyUuid: this.pagination.companyUuid,
        }
        this.dialogResetCupVisible = true
      },
      handleResetEmployeeCups() {
        this.cup.freeCups = parseInt(this.cup.freeCups)
        this.cup.canUseCups = parseInt(this.cup.canUseCups)
        companyEmployeeResetCups(this.cup).then(res => {
          this.$message.success('设置成功')
          this.employees[this.employeeIndex].freeCups = this.cup.freeCups
          this.employees[this.employeeIndex].canUseCups = this.cup.canUseCups
          this.dialogResetCupVisible = false
          return
        })
      },
      handleResetEmployeePassword() {
        let reset = this.reset
        let msg = checkObj(reset, [
          {value: 'password', message: '请输入新的密码'},
          {value: 'repeatedPassword', message: '请确认新的密码'},
        ])
        if (!msg.status) {
          this.$message.warning(msg.message);
          return
        }
        if (reset.password !== reset.repeatedPassword) {
          this.$message.warning('两次密码不匹配')
          return
        }
        companyEmployeeResetPassword(this.reset).then(res => {
          this.$message.success('重置成功')
          this.dialogFormVisible = false
        })
      },
      loadEmployee: function (companyUuid, force = false, name = '') {
        let vue = this
        if (!force && vue.employees.length > 0) {
          return
        }
        this.pagination.companyUuid = companyUuid
        this.pagination.name = name

        companyEmployeeUnion(this.pagination).then(res => {
          vue.employees = res.data.list || []
          vue.pagination.total = parseInt(res.data.total)
        })
      },
      handleSearchEmployee(val) {
        this.loadEmployee(this.pagination.companyUuid, true, val)
      },
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        companyEmployeeList(this.pagination).then(res => {
          this.employees = res.data || []
        })
      },


      handleDeleteEmployee(employee, index) {
        this.$confirm('删除员工将不可恢复，是否继续', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          companyEmployeeDelete({companyUuid: this.pagination.companyUuid, employeeUuid: employee.uuid}).then(res => {
            this.employees.splice(index, 1)
            this.$message.success('删除成功')
            this.pagination.total -= 1;
          })
        }).catch(() => {

        })
      },
    },
  }
</script>

<style lang="scss">

  body.dark, body.light {
    .dealer-company-employee-list {
      .el-button--text {
        font-size: 12px;
      }

      .warning {
        span {
          color: $btn_color;
        }
      }

      .danger {
        span {
          color: $btn_sec_color;
        }
      }
    }
  }
</style>
